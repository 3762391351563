.ui.category.search.globalSearch .results {
  width: 48em;
  left: auto;
  right: 0;

  .status {
    margin-left: 1rem;
  }
  .label {
    float: right;
    min-width: 6rem;
  }
}
