body {
  overflow-x: initial !important;
  display: inline-block;
  min-width: 100% !important;
}
@media only screen and (max-width: 767px) {
  .container.ui.container {
    width: auto !important;
    margin-left: 0em !important;
    margin-right: 0em !important;
  }
}

.main-container {
  position: relative;
  min-height: 100vh;
}
.login-container {
  padding: 0 1rem 0 1rem;
}
.app-container {
  padding: 0 1rem calc(1rem + 35px) 1rem;
  min-height: 50vh;
}
.menu-logo {
  width: 80px;
  min-width: 80px;
}

.stretch-table {
  display: none;
}
